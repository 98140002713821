import React from 'react';
// import './assets/css/main.css'
import {useRoutes} from "react-router-dom";
import AppRoutes from "./components/routing/Routes";

function App() {
    return (
        <React.Fragment>
            {useRoutes(AppRoutes())}
        </React.Fragment>
    );
}

export default App;
