import {Outlet} from "react-router-dom";
import React from "react";
import Home from "../home/Home";

const AppRoutes = () => [
    {
        path: '/',
        element: <Outlet/>,
        children: [
            {path: '', element: <Home/>},
        ],
    },
];

export default AppRoutes;