import axios from "axios";
import cookie from "react-cookies";

// baseURL: process.env.REACT_APP_BASE_API_URL,
// baseURL: 'http://localhost:8000/api',
export const apiAxios = axios.create({
    baseURL: 'https://anamazingwizard.com/api',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
    },
    withCredentials: true,
})
export const apiAxiosCSRF = axios.create({
    baseURL: 'https://anamazingwizard.com/api',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'X-CSRFTOKEN': cookie.load("csrftoken")
    },
    withCredentials: true,
})

export const getFAQ = async () => {
    return await apiAxios.get(`faq/`)
}
export const getSiteData = async () => {
    return await apiAxios.get(`site-data/`)
}
export const getCsrfToken = async () => {
    return await apiAxios.get(`csrftoken`)
}
export const subscribe = async (data) => {
    return await apiAxiosCSRF.post(`subscribe`, data)
}