import HomeIcon from "@mui/icons-material/Home";
import StorageIcon from "@mui/icons-material/Storage";
import SchoolIcon from "@mui/icons-material/School";
import ConstructionIcon from "@mui/icons-material/Construction";
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ScienceIcon from "@mui/icons-material/Science";
import PetsIcon from '@mui/icons-material/Pets';
import SailingIcon from '@mui/icons-material/Sailing';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import FaceIcon from '@mui/icons-material/Face';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import React from "react";

export const NavLinkData = [
    {
        'icon': <HomeIcon/>,
        'name': 'Home',
        'path': '/'
    },
]