import React from 'react';
import {Link} from "react-router-dom";

function NavLink({data, t}) {
    const {name, icon, path} = data;
    return (
        <li className="nav-item">
            <Link to={path}>{icon} {t(name.toLowerCase())}</Link>
        </li>
    );
}

export default NavLink;