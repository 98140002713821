import React from 'react';
import Header from "../home/navigation/Header";
import Footer from "../home/Footer";
import {Link, useLocation} from "react-router-dom";
// import BreadcrumbItem from "../breadcrumb/Breadcrumb";
import ScrollToTop from "react-scroll-to-top";
import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faTwitter,
    faDiscord,
    faFacebook,
    faTiktok,
    faInstagram,
    faYoutube
} from "@fortawesome/free-brands-svg-icons";
import polygon from "../../assets/images/polygon-scatter-haikei.svg";

function SocialItem({icon, name, url}) {
    return (
        <React.Fragment>
            <li>
                <a href={url} className={name}>
                    <FontAwesomeIcon icon={icon} style={{color: 'white'}}/>
                </a>
            </li>
        </React.Fragment>
    )
}

function PageWrapper({children, breadcrumb}) {
    const {t} = useTranslation()
    const {pathname} = useLocation();
    const scStyle = {
        backgroundColor: '#a7774e'
    }
    const bg = {
        background: `url(${polygon}) repeat-x 50% 0%`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'auto',
        boxShadow: 'inset 0 -13px 13px #252a34'
    }
    return (
        <React.Fragment>
            <Header/>
            <main className="container-fluid mt-0 p-0" style={bg}>
                <div className="vertical-social-media">
                    <ul className="p-lg-0 mt-5 p-sm-0">
                        <SocialItem icon={faTwitter} name='twitter'
                                    url="https://twitter.com/AnAmazingWizard"/>
                        <SocialItem icon={faDiscord} name='discord'
                                    url="https://discord.com/invite/nS6FvfzhJv"/>
                        <SocialItem icon={faFacebook} name='facebook'
                                    url="https://www.facebook.com/an.amazing.wizard.game"/>
                        <SocialItem icon={faInstagram} name='instagram'
                                    url="https://www.instagram.com/an_amazing_wizard/"/>
                        <SocialItem icon={faTiktok} name='tiktok'
                                    url="https://www.tiktok.com/@anamazingwizard"/>
                        <SocialItem icon={faYoutube} name='youtube'
                                    url="https://youtube.com/@anamazingwizardgame"/>
                    </ul>
                </div>
                {children}
                <ScrollToTop smooth color="white" style={scStyle}/>
            </main>
            <Footer/>
        </React.Fragment>
    );
}

export default PageWrapper;