import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from "../../assets/images/logo.png"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelope,
    faHandPointRight,
} from "@fortawesome/free-solid-svg-icons"
import {
    faTwitter,
    faDiscord,
    faFacebook,
    faTiktok,
    faInstagram,
    faYoutube, faRedhat
} from "@fortawesome/free-brands-svg-icons";
import wave from "../../assets/images/wave-haikei.svg"
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {getSiteData, subscribe} from "../api/api";
import {STATUS} from "../api/status";

function SocialItem({icon, name, url}) {
    return (
        <React.Fragment>
            <li>
                <a href={url} className={name}>
                    <FontAwesomeIcon icon={icon}/>
                </a>
            </li>
        </React.Fragment>
    )
}

export function Subscribe({id, style}) {
    const [emailValue, setEmailValue] = React.useState({
        "old_value": "",
        "push_value": "",
    })
    const [subscribed, setSubscribed] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState("")
    const onEmailInput = ({target: {value}}) => {
        setEmailValue(prevState => {
            return {
                ...prevState,
                push_value: value,
            }
        })
    }
    const onFormSubmit = e => {
        e.preventDefault()
        const old_value = emailValue["old_value"]
        const push_value = emailValue["push_value"]
        if (push_value && old_value !== push_value) {
            subscribe({
                'email': push_value,
            }).then(response => {
                const {status} = response;
                if (status === STATUS.HTTP_201_CREATED) {
                    setSubscribed(true)
                    setErrorMessage("")
                }
            }).catch(error => {
                setSubscribed(false)
                setErrorMessage(error.response?.data?.email[0])
            })
            setEmailValue(prevState => {
                return {
                    ...prevState,
                    old_value: push_value,
                }
            })
        }
    }
    return (
        <React.Fragment>
            <div style={{
                backgroundColor: "rgba(49, 71, 98, 0.57)", borderTop: "1px solid rgba(13, 202, 240, 0.5)", ...style
            }}>
                <div id={id} className="container py-5">
                    <Form onSubmit={onFormSubmit} method="POST">
                        <div className="d-flex align-items-center gap-3 justify-content-center">
                            <Form.Group className="w-100" controlId="formEmail"
                                        style={{maxWidth: "500px"}}>
                                <Form.Control type="email"
                                              placeholder="Enter email"
                                              onChange={onEmailInput}
                                              value={emailValue["push_value"]}/>
                            </Form.Group>
                            <Button variant="outline-info" type="submit" className="fs-6">
                                Subscribe
                            </Button>
                        </div>
                    </Form>
                    <p className="text-uppercase text-center mt-4 fs-5 fw-bold"
                       style={{letterSpacing: "3px", color: "#e8b572"}}>
                        subscribe to get development updates!
                    </p>
                    {
                        subscribed
                            ? <p className="text-center text-uppercase fs-5 text-info fw-bold"
                                 style={{letterSpacing: "3px"}}>Subscribed <FontAwesomeIcon
                                icon={faRedhat}/></p>
                            :
                            <p className="text-center text-uppercase fs-6 text-danger fw-bold">{errorMessage}</p>
                    }
                </div>
            </div>
        </React.Fragment>
    )
}

function Footer() {
    const {t} = useTranslation()
    const bg = {
        background: `url(${wave}) repeat-x 50% 0%`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        backgroundPositionY: 'bottom',
        boxShadow: "2px 2px 2px 2px black",
        minHeight: 450
    }
    return (
        <React.Fragment>
            <Subscribe id="newsletter-bottom"/>
            <footer className="py-4" style={bg}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 text-start">
                            <div className="mb-5">
                                <a href="#">
                                    <img className="mw-100" src={logo}
                                         height="80"
                                         alt="an amazing wizard"/>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-5 text-start">
                            <div className="d-flex justify-content-between">
                                <ul className="footer-list">
                                    <li>
                                        <a href="#about">About</a>
                                    </li>
                                    <li>
                                        <a href="#media">Media</a>
                                    </li>
                                    <li>
                                        <a href="#about">Features</a>
                                    </li>
                                    <li>
                                        <a href="#faq">FAQ</a>
                                    </li>
                                    <li>
                                        <a target="_blank"
                                           href="https://drive.google.com/drive/folders/16DwQ9QtiGMycWAWOqgZOfGN1gz8Fv8_U"
                                           style={{color: '#56b556'}}>PressKit
                                        </a>
                                    </li>
                                </ul>
                                <ul className="footer-list ">
                                    <li>
                                        <a target="_blank"
                                           className="text-uppercase"
                                           href="https://store.steampowered.com/app/1372660/An_Amazing_Wizard/"
                                           style={{color: '#fb265b', fontSize: "22px"}}>Wishlist
                                            Now!
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 text-end">
                            <div id="social-media">
                                <ul className="ps-0 mt-1 p-sm-0">
                                    <SocialItem icon={faTwitter} name='twitter'
                                                url="https://twitter.com/AnAmazingWizard"/>
                                    <SocialItem icon={faDiscord} name='discord'
                                                url="https://discord.com/invite/nS6FvfzhJv"/>
                                    <SocialItem icon={faFacebook} name='facebook'
                                                url="https://www.facebook.com/an.amazing.wizard.game"/>
                                    <SocialItem icon={faInstagram} name='instagram'
                                                url="https://www.instagram.com/an_amazing_wizard/"/>
                                    <SocialItem icon={faTiktok} name='tiktok'
                                                url="https://www.tiktok.com/@anamazingwizard"/>
                                    <SocialItem icon={faYoutube} name='youtube'
                                                url="https://youtube.com/@anamazingwizardgame"/>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="contact" className="text-end mt-3">
                                <h2 className="h5">Have some question? CONTACT US!</h2>
                                <span
                                    className="d-flex align-items-center justify-content-end mt-2"
                                    style={{color: '#FF2E63'}}>
                              <FontAwesomeIcon icon={faEnvelope}/>
                              <a href="mailto:anamazingwizard.info@gmail.com"
                                 className="px-2 text-decoration-none"
                                 style={{color: '#dedede'}}>anamazingwizard.info@gmail.com</a>
                            </span>
                            </div>
                        </div>
                    </div>
                    <div id="copyright">
                        <div className="text-center wrapper">
                            <p className="mb-0">
                                <b className="text-success fs-0_8">An Amazing Wizard</b>
                            </p>
                            <p className="text-white mb-0">Copyright © 2024 Jakub Dąbrowski</p>
                        </div>
                    </div>
                </div>
            </footer>

        </React.Fragment>
    );
}

export default Footer;